.plan {
	display: flex;
    flex: 1 1;
}

.plan-greeting {
    text-align: center;
    h1 {
        font-weight: 100;
        font-size: 2em;
    }
}

.plan-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.radio-plan-mobile-nav {
	display: none;
}

.plan-mobile-nav {
	flex-basis: 100%;
	display: none;
}

.plan-main {
    order: 2;
    flex-grow: 1;
    //flex: 1;
    // margin-left: 1em;
    display: flex;
    flex-direction: column;
}
.plan-side {
    order: 1;
    flex-basis: 270px;
    margin-right: 1em;
}

.plan-evaluation {
    display: flex;
    margin-left: 1em;
    flex-basis: 70px;
    order: 3;
}

@media screen and (max-width: 959px) {
	.plan-mobile-nav {
		display: flex;
		flex-basis: auto;
		align-items: center;
		justify-content: center;
		padding: 1em 0 0;
		margin: 0 -1em 0 -1em;
		label {
			display: block;
			padding: .65em 1.25em;
			background: none;
			color: #888;
			font-weight: bold;
            background: none;
			text-decoration: underline;
			border-radius: $radius;
			cursor: pointer;
			i {
				display: none;
				margin-right: .25em;
				color: $primary;				
			}
			&:not(:last-child) {
				margin-right: .5em;
			}
			&:hover {
                color: #000;
			}
		}
	}
	.plan-main {
		margin-left: 0;
	}
	.plan-side {
		display: none;
	}
	.plan {
		.panel-evaluation {
			display: none;
		}
	}

	// Make active nav button

	#cb-plan-side:checked ~ .plan-mobile-nav {
		label:nth-child(1) {
			background: #888;
			text-decoration: none;
			color: #fff;
			i {
				display: inline-block;
			}
		}
	}

	#cb-plan-main:checked ~ .plan-mobile-nav {
		label:nth-child(1) {
			background: #888;
			text-decoration: none;
			color: #fff;
			i {
				display: inline-block;
			}
		}
	}

	#cb-plan-evaluation:checked ~ .plan-mobile-nav {
		label:nth-child(2) {
			background: #888;
			text-decoration: none;
			color: #fff;
			i {
				display: inline-block;
			}
		}
	}

	// Show active nav pane

	#cb-plan-side:checked ~ .plan {
		.plan-row {
			.plan-side {
				display: block;
				flex-basis: 100%;
			}
			.plan-main {
				display: none;
			}
			.panel-evaluation {
				display: none;
			}
		}
	}


	#cb-plan-main:checked ~ .plan {
		.plan-row {
			.plan-side {
				display: none;
			}
			.plan-main {
				display: block;
			}
			.panel-evaluation {
				display: none;
			}
		}
	}

	#cb-plan-evaluation:checked ~ .plan {
		.plan-row {
			.plan-side {
				display: none;
			}
			.plan-main {
				display: none;
			}
			.panel-evaluation {
				display: block;
				flex-basis: 100%;
				min-width: 480px;
                max-width: 640px;
                flex: 1;
                margin-left: auto;
                margin-right:  auto;
				.row {
					.icons {
						display: none;
						border-radius: $radius 0 0 0;
						border-right: 1px solid #ddd;
						label {
							transform: scaleX(-1);
						}
					}
					.main {
						display: flex !important;
					}
				}
			}
		}
	}
}

.plan-side-buttons {
    a {
        display: block;
        color: $primary-dark;
        text-decoration: none;
        padding: .75em 1.5em;
        margin: 0 0 4px 0;
    }
}

.planner-top-nav {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 1em 0;
    font-size: .85em;
    .btn-plain, label {
        @include button_default();
        font-size: 1em;
        background: #fff;
        color: #444;
        border: 1px solid #ddd;
        &:hover {
            background: #f4f4f4;
            color: #000;
			border: 1px solid #ccc;
        }
    }
    input[type="checkbox"] {
        display: none;
    }
    label {
        margin-left: .5em;
        svg {
            height: 1em;
            width: auto;            
        }
    }
}




.planner-switch {
    padding: 1em 0;
    position: relative;
    label {
        margin-left: 0;
		background: #F7F7F7;
    }
    .options {
        position: absolute;
        z-index: 10000;
        left: 0;
        top: calc(100% + 1px);
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #fff;
        a {
            display: block;
            padding: .65em 1em;
            &:hover {
                background: #efefef;
            }
        }
    }
}


.planner-hours {
    display: flex;
    flex: 1 1;
	flex-direction: column;

	.planner-hours-header {
		margin-bottom: 1em;
		h3 {
			margin: 1em 0 0;
			text-transform: capitalize;
			font-weight: 600;
		}
		.navbar {
			margin: 0;
			display: flex;
			align-items: center;
			input[type="checkbox"] {
				display: none;
			}
			a, label {
				@include button_default();
				display: inline-block;
				padding: .5em 1em;
				font-size: .9em;
				margin-right: .5em;
			}
			.btn-plain {
				display: inline-flex;
				align-items: center;
				background: none;
				color: #444;
				padding: .35em .6em;
				border: 1px solid #ddd;
				font-size: .85em;
				margin-right: 0;
				&:hover {
					background: $primary-lightest;
					color: #000;
				}
				svg {
					height: 14px;
					width: auto;
				}
			}
			aside {
				margin-left: auto;
				display: flex;
				align-items: center;
				a, label {
					&:not(:first-child) {
						margin-left: 4px;
					}
				}
			}
		}		
	}

    >h3 {
		display: flex;
		align-items: center;
        color: $primary;
        font-size: 1.2em;
        font-weight: 600;
        margin: 0 0 1em 0;
		text-transform: capitalize;
		>a {
			font-size: 12px;
			color: #777;
			font-weight: normal;
			text-transform: initial;
		}
		aside {
			margin-left: auto;
			font-size: 1rem;
			text-transform: none;
			a {
				@include button_default();
				display: inline-block;
				padding: .5em 1em;
				font-size: .9em;
				margin-left: .5em;
			}
		}
	}

	.hour {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		position: relative;
		transition: all .15s;
		min-height: 40px;
		border-top: 2px dotted #ddd;

		>span {
			position: absolute;
			width: 46px;
			left: 0px;
			top: -7px;
			padding: 0 8px 0 0;
			background: #f7f8fa;
			color: #555;
			font-size: 13px;
			font-weight: 300;
			line-height: 100%;
        }
        

		&:hover {
			border-top: 2px solid #cbd2db;
			>span {
				font-weight: bold;
			}
		}
	}
	.hour-short {
		//height: 34px;
		>span {
			color: #ccc;
		}
	}
}


.meal-item {
	display: flex;
	width: calc(100% - 116px);
	margin: 4px 16px 4px 100px;
	background: #fff;
	border: 1px solid #4eb32d;
	border-left: 10px solid #4eb32d;
	font-size: .9em;
	transition: all .15s;
	transform: translateY(0);
	.meal-item-body {
		display: flex;
		flex: 1;
	}            
	.meal-item-title {
		display: flex;
		flex-basis: 13em;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		color: #4eb32d;
		font-weight: 600;
		text-decoration: none;
		text-align: center;
		padding: .5em 1em;
		p {
			margin: 0 0 4px 0;
			font-size: 1.2em;
			white-space: nowrap;
		}
		p.title {
		}
		.time {
			display: block;
			font-size: 12px;
			font-weight: 700;
			color: #777;    
			.b-not-used {
				display: inline-block;
				background: rgba(0,0,0,.5);
				font-weight: bold;
				color: #fff;
				padding: 3px 5px;
				margin: 0 1px;
				border-radius: 3px;
			}
		}
		.meal-item-profiles {
			display: flex;
			align-items: center;
			font-weight: 300;
			font-size: 11px;
			margin-top: .5em;
			b  {
				font-weight: normal;
				padding: 3px 4px;
				border-radius: 2px;
				margin-right: 2px;
			}
		}					
	}
	.meal-item-details {
		padding: 10px 10px;
		display: flex;
		flex: 1 1;
		flex-direction: column;
		justify-content: center;
		p {
			margin: 0;
			&:first-child {
				font-size: 1.15em;
				margin-bottom: 4px;
			}
		}
		.meal-item-details-title {
			>span {
				display: block;
				font-size: 9px;
				text-transform: uppercase;
				color: #555;
			}
		}
		.title-row {
			display: flex;
			align-items: center;
			margin: 2px 0;
			.recipe-preview {
				width: 6em;
				height: 4em;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: $radius;
				margin-right: .5em;
			}
		}
	}
	.meal-item-buttons {
		margin-left: auto;
		align-items: center;
		display: flex;
		padding-left: .5em;
		padding-right: .5em;
		a {
			display: inline-block;
			padding: 5px;
			color: #ccc;
			text-decoration: none;
			cursor: pointer;
			&:hover {
				color: $primary;
			}
		}
	}
	&:hover {
		transform: translateY(-3px);
		box-shadow: 0 3px 6px rgba(0,0,0,.15);
		.meal-item-title {
			p.title {
				span {
					border-bottom: 2px solid $primary;
				}
			}
		}
	}
	@media screen and (max-width: 839px) {
		width: calc(100% - 66px);
		margin: 4px 16px 4px 50px;	
	}
	@media screen and (max-width: 639px) {
		.meal-item-body {
			flex-wrap: wrap;
			.meal-item-title {
				flex-basis: 100%;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				.title {
					margin: 0 .5em 0 0;
				}
				.meal-item-profiles {
					margin-left: .5em;
				}
			}
			.meal-item-details {
				flex-basis: 100%;
			}

		}
		width: calc(100% - 66px);
		margin: 4px 16px 4px 50px;	
	}
}

.meal-item-drink-regime {
	border: 1px solid #07f;
	border-left: 10px solid #07f;
	.meal-item-title {
		color: #07f;
	}
	&:hover {
		.meal-item-title {
			p.title {
				span {
					border-bottom: 2px solid #48f;
				}
			}
		}
	}	
}

.meal-item-standalone {
	width: 100%;
	margin-left: 0;
}

.meal-item-training {
	border: 1px solid #f94;
	border-left: 10px solid #f94;
	.meal-item-title {
		color: #f94;
	}
	&:hover {
		.meal-item-title {
			p.title {
				span {
					border-bottom: 2px solid #f94;
				}
			}
		}
	}
}





.meal-item-new {
	width: calc(100% - 76px);
	margin: 2em 0 2em 70px;
	border: 2px solid #e1e7ee;
	border-left: none;
	font-size: .9em;
    position: relative;
	border-radius: 3px;
	padding-left: 5px;
	// background: #f8f9fb;
	background-image: linear-gradient(#f6f7f8, #fff);
    cursor: default;
	&:before {
		content: "";
		position: absolute;
		left: -1px;
		top: 0;
		width: 5px;
		height: 100%;
		border-radius: 3px 0 0 3px;
		background-image: linear-gradient(#cdf5d8, #32ad5c);
	}
    .title {
        width: 100%;
        display: flex;
		flex-wrap: wrap;
        align-items: center;
        padding: 1em 1.5em;
		background: #fff;
		border-bottom: 2px solid #e1e7ee;
		color: $text;
        p {
            margin: 0;
			em {
				font-style: normal;
				margin: 0 .5em;
			}
			b {
				font-size: 1.2em;
				text-transform: uppercase;
			}
		}
        aside {
            margin-left: auto;
			display: flex;
			align-items: center;
			>div {
				display: inline;
				position: relative;
			}
            >div>a {
                color: #888;
                margin-left: 1em;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: #eee;
                border-radius: 100px;
                padding: 6px 10px;
				font-size: .95em;
            }
            .btn-settings {
                width: 1.85em;
                height: 1.85em;
            }
            a:not(.btn-settings) {
                i {
                    margin-right: .5em;
                }
            }
		}
		.eval {
			flex-basis: 100%;
			display: flex;
			padding: .75em 0 0;
			.col {
				text-align: center;
				font-size: .85em;
				color: #777;
				line-height: 150%;
			}
			.col + .col {
				margin-left: 1.75rem;
			}
		}		
    }
	.item-modifications {
		padding: 4px 0;
		font-size: .9em;
		span {
			position: relative;
			display: inline-block;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				border-bottom: 1px dotted #888;
			}
		}
	}
	.item-undesirable {
		padding: 0 0 3px 0;
		font-size: .9em;
		color: #f00;
		margin: 0;
	}
}

@media screen and (max-width: 959px) {
    .meal-item-new {
        margin: 1em 0 1em 40px;
    }
}


.meal-item-new-training {
	.title {
		background: #fff7de;
		border-bottom: none;
		aside {
			text-align: right;
			b {
				font-size: 1em;
				text-transform: none;
			}
		}
	}
	&:before {
		background-image: linear-gradient(rgb(255, 250, 224), #e4a909);
	}
}
.meal-item-new-drink-regime {
	&:before {
		background-image: linear-gradient(rgb(194, 248, 255), #14b8f8);
	}
}


.meal-item-group {
    margin-bottom: 1px;
    position: relative;
    .settings {
        top: 2.75em;
    }
}

.meal-item-group-minimalistic {
	margin: 1em 1.5em;
	.inner {
		display: inline-flex;
		align-items: center;
		border-radius: 100px;
		border: 2px solid #d8d8d8;
		padding: .5em .5em .5em 1.5em;
		font-size: 1.05em;
		color: $text;
		background: #fff;
	}
	p {
		margin: 0;
		color: $text-light;
	}
	b {
		color: $text;
		font-size: 1.1em;
		margin-left: .35rem;
	}
	aside {
		margin-left: 3em;
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.6em;
			height: 1.6em;
			background: #8d95a4;
			color: #fff;
			border-radius: 200px;
		}
	}
}

.meal-item-group-header {
    display: flex;
    align-items: center;
    padding: 1.5em;
    background: #F7F8FA;
    >div {
        margin: 0;
        flex: 1;
        position: relative;
        a {
            color: #777;
            text-transform: uppercase;
            font-size: .9em;
        }
        input {
            display: block;
            font-weight: bold;
            background: none;
            padding: 6px 10px 10px 0;
            width: calc(100% - 5em);
            border: none;
            font-size: 1.35em;
			color: $text;
        }
        input:focus {
            outline: none;
        }
        b {
            display: block;
            font-size: 1.3em;
            margin-top: 4px;
        }
    }
    .group-type-select {
        position: absolute;
        left: 0;
        top: 1.5em;
        background: #fff;
        z-index: 200;
        border: 1px solid #eaeaea;
        box-shadow: 0 4px 6px rgba(0,0,0,.15);
        a {
            display: block;
            padding: .85em 3em .85em 1em;
            &:hover {
                background: #f4f4f4;
            }
        }        
    }
    aside {
        margin-left: auto;
		font-size: .9em;
		display: flex;
		align-items: center;
		>div {
			position: relative;
			&:not(:last-child) {
				margin-right: .75em;
			}
		}
        >div>a {
            text-decoration: none;
            display: inline-flex;
            padding: 6px 8px;
            border-radius: 50px;
            background: #EBEBEB;
            color: #000;
        }
        a:not(:first-child) {
            margin-left: .85em;
        }
        i + span {
            margin-left: 8px;
        }
		a.btn-add-food-or-recipe {
			background: $primary;
			color: #fff;
		}
    }

	@media screen and (max-width: 719px) {
		flex-direction: column;
		padding: 1em;
		align-items: flex-start;
		aside {
			margin-left: 0;
		}
	}	
}




.settings {
	user-select: none;
	position: absolute;
	z-index: 150;
	right: 0;
	top: 2.25em;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 8px 16px rgba(0,0,0,.15);
	padding: .25em 0;
	border: 1px solid #efefef;
	font-size: .75rem;
	a {
		display: block;
		padding: .75em 2em;
		margin: 0 !important;
		white-space: nowrap;
		&:hover {
			color: $primary;
		}
	}
	p {
		margin: 0;
		padding: .75em 2em;
		select {
			width: 100%;
			padding: 3px 4px;
		}
	}
}






.meal-item-group-foods {
    display: flex;
    flex-direction: column;
}

.meal-item-food {
    display: flex;
    align-items: center;
    padding: .9em 1.5em;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
    .icon {
        flex-basis: 50px;
        flex-shrink: 0;
        height: 50px;
        margin-right: 2em;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .food-title {
        display: flex;
        flex: 1;
        flex-direction: column;
        h4 {
            font-weight: bold;
            font-size: 1.05em;
            margin: 0 0 .15em 0;
            display: flex;
            align-items: center;
            span {
                background: #f00;
                color: #fff;
                font-size: 12px;
                font-weight: normal;
                display: inline-block;
                padding: 2px 3px;
                border-radius: 3px;
                margin-left: .5rem;
            }
            p {
                font-size: 12px;
                font-weight: normal;
                margin: 0 0 0 1.5em;
                padding: 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                line-height: 100%;
                input {
                    margin-right: 4px;
                }
            }
        }
    }
	.amount {
		display: flex;
		align-items: center;
		font-size: .9em;
		.amount-input {
			margin-right: 1em;
			input {
				width: 4em;
				text-align: right;
				padding: 3px 3px;
				border: 1px solid $border;
				border-radius: 3px;
				margin-right: 5px;
			}
		}
	}
    .options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .option {
            display: none;
            position: relative;
            margin-left: .5em;
        }
        a {
            font-size: 1em;
            padding: .5em .3em;
            color: #888;
            &:hover {
                color: #000;
            }
        }
        .delete {
            color: #000;
            margin-left: .5em;
            &:hover {
                color: #f00;
            }
        }
    }
    &:hover {
		border-top: 1px solid $border;
		border-bottom: 1px solid $border;
		.options {
            .option {
                display: inline-block;
            }
        }
    }
}

.meal-item-food-nohover {
	.options {
		.option {
			display: inline-block;
			opacity: .5;
		}
	}
}



.food-title-alergens {
    font-size: 12px;
    margin: 0;
    flex-wrap: wrap;
    line-height: 130%;
    max-width: 380px;
}
.food-title-undesirable-info {
    color: #f00;
    // font-weight: bold;
    // text-transform: uppercase;
}


.meal-item-food:last-child {
    margin-bottom: .5em;
}

.meal-item-adjust-amount {
    position: absolute;
    right: -1em;
    top: calc(100% + 1.25em);
    background: #fff;
    padding: 1.2em;
    border: 1px solid #ddd;
    p {
        margin: 0;
        white-space: nowrap;
        display: flex;
    }
    input {
        width: 5em;
    }
    span {
        margin: 0 6px 0 4px;
        display: flex;
        align-items: center;
    }
    button {
        margin-left: .5em;
        cursor: pointer;
        border: none;
        background: $primary;
        color: #fff;
        border-radius: 3px;
    }
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        right: 1rem;
        top: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ccc transparent;        
    }
    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        right: 1rem;
        top: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent;        
    }
}

.meal-item-group-add-food {
    padding: 1.25em 1.5em 1em 0;
	border-top: 1px solid #ccc;
	margin-left: 1.5em;
	margin-top: .25em;
	max-width: 280px;
    a {
        display: inline-flex;
		align-items: center;
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.5em;
			height: 1.5em;
			font-size: 1.25em;
			background: #ebebeb;
			color: $text;
			border-radius: 100px;
			margin-right: .75rem;
		}
        &:hover {
			span {
				background: $primary;
				color: #fff;
			}
        }
    }
}

.food-modifications-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -.5em;
    .mod {
        flex-basis: calc(25% - .5em);
        text-align: center;
        margin: 0 0 .5em .5em;
        position: relative;
    }
    label {
        display: block;
        cursor: pointer;
        font-size: .9em;
        border: 1px solid #fff;
        padding: .5em;
    }
    input {
        display: none;
    }
    input:checked + label {
        border: 1px solid $primary;
        font-weight: bold;
        color: $primary;
    }
    img {
        height: 50px;
        width: auto;
        display: block;
        margin: 1em auto;
    }
    .link-help {
        position: absolute;
        right: 4px;
        top: 4px;
        display: inline-block;
        z-index: 20;
        width: 1rem;
        height: 1rem;
        &:before {
          content: "?";
          background: none;
          display: flex;
          width: 1rem;
          height: 1rem;
          align-items: center;
          justify-content: center;
          color: #aaa;
          font-size: 10px;
          border: 1px solid #aaa;
          border-radius: 50%;
          z-index: 1;
        }  
        &:hover {
            &:before {
                border: 1px solid #444;
                color: #444;
            }
        }
      }    
    @media screen and (max-width: 719px) {
        .mod {
            flex-basis: 33.33%;
        }
    }
    @media screen and (max-width: 479px) {
        .mod {
            flex-basis: 50%;
        }
    }
}

.meal-item-group-change-amount {
    display: flex;
    justify-content: center;
    input {
        width: 5em;
        padding: 4px;
        text-align: center;
    }
    span {
        margin: 0 1em 0 .5em;
        display: flex;
        align-items: center;
    }
    button {
        @include button_default();
        border: none;
        i {
            margin-right: 0;
        }
    }
}

.add-recipe-or-plate {
    border-top: 1px solid #ddd;
    max-width: 460px;
    display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    
    a {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        span {
            background: #ebebeb;
            display: flex;
            width: 1.5em;
            height: 1.5em;
            border-radius: 100px;
        }

    }
    a + a {
        margin-left: 1em;
    }
}










































.plan-generator {
	h2 {
		font-size: 1.1em;
		color: $primary;
		font-size: 1.6em;
		text-align: center;
		font-weight: 400;
		margin: 1em 0;
	}
	.sub-hd {
		color: #888;
		font-size: .9em;
		margin: 2em 0 1em 0;
		color: $primary;
		font-weight: bold;
		&:first-child {
			margin-top: 0;
		}
	}
	.profiles {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.profile {
			min-width: 160px;
			padding: 1em;
			text-align: center;
			label {
				cursor: pointer;
				display: block;
				.picture {
				}
				.picture-box {
					margin: 0 auto;
					width: 120px;
					height: 120px;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 300px;
				}
				h3 {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1em;
					font-weight: normal;
					margin: 1em 0 0;
					color: #999;
				}
				input {
					display: none;
				}
				span {
					display: inline-flex;
					color: $primary;
					margin-left: .5em;
				}
				.no {
					display: inline-flex;
					color: #999;
				}
				.yes {
					display: none;
				}
				input:checked + h3 {
					color: $primary;
					font-weight: bold;
					.no {
						display: none;
					}
					.yes {
						display: inline-flex;
					}
				}
			}
		}
	}
	.options-row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		label {
			cursor: pointer;
			flex-basis: calc(33.33% - 1em);
			display: flex;
			align-items: center;
			padding: 0 .5em;
			font-size: .9em;
			margin: 0 0 .5em 0;
			span {
				display: inline-block;
				width: 1.5em;
				height: 1.5em;
				border: 2px solid #aaa;
				background: #fff;
				padding: 4px;
				border-radius: 50%;
				margin-right: .5em;
				flex-shrink: 0;
			}
			input {
				display: none;
			}
			input:checked + span {
				border: 2px solid $primary;
				background: $primary;
			}
			select {
				margin-left: auto;
				margin-right: 2em;
			}
		}
	}
	.options-row-4 {
		label {
			flex-basis: calc(25% - 1em);
		}
	}
	.options-row-3 {
		label {
			flex-basis: calc(33.33% - 1em);
		}
	}
	.options-row-2 {
		label {
			flex-basis: calc(50% - 1em);
		}
	}

	@media screen and (max-width: 719px) {
		.options-row label, .options-row-4 label {
			flex-basis: calc(50% - 1em);
		}
		.options-row label, .options-row-3 label {
			flex-basis: calc(50% - 1em);
		}
	}
	@media screen and (max-width: 479px) {
		.options-row label{
			flex-basis: calc(100% - 1em);
		}
	}

	.submit {
		padding: 2em 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			@include button_default();
			border: none;
		}
	}
}

.generator-form {
	table {
		margin: 1em 0;
		td {
			padding: 2px 1.5em 2px 0;
		}
	}
}


.generator-results-info {
	padding: 0 1rem;
	h2 {
		color: $primary;
		font-size: 1.8em;
		margin: 0 0 1rem 0;
	}
	table {
		font-size: .9em;
		td:first-child {
			padding-right: 1em;
		}
	}
}




